<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{'640px': '100vw'}"
    :closable="false"
    :header="`Delete '${attendeeName}'`"
  >
    <div class="dialog-body">
      <p>Are you sure you wish to delete this attendee?</p>
    </div>

    <div class="dialog-buttons">
      <loading-spinner
        v-if="$isLoading('deleteAttendee')"
        :size-class="'fa-2x'"
      />

      <button
        :disabled="$isLoading('deleteAttendee')"
        @click="closeDialog"
      >
        Cancel
      </button>

      <button
        class="btn-primary"
        :disabled="$isLoading('deleteAttendee')"
        @click="submit"
      >
        Delete attendee
      </button>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { loadable } from 'vue-is-loading';
import { getErrorMessage } from '#lib/Errors';
import { successToast, errorToast } from '#ui/lib/toast';
import LoadingSpinner from '#ui/components/LoadingSpinner';

// Setup

const store = useStore();
const id = ref(0);
const attendeeName = ref('');

// Open/close

const visible = ref(false);

function getAttendeeDisplay(attendee) {
  if (attendee.first_name && attendee.last_name) {
    return `${attendee.first_name} ${attendee.last_name}`;
  }

  if (attendee.email) {
    return attendee.email;
  }

  if (attendee.phone_number) {
    return attendee.phone_number;
  }

  if (attendee.alias) {
    return attendee.alias;
  }

  return 'Person';
}

const openDialog = (attendee) => {
  id.value = attendee.id;
  attendeeName.value = getAttendeeDisplay(attendee);

  visible.value = true;
};
const closeDialog = () => {
  visible.value = false;
};
defineExpose({ openDialog, closeDialog });

// Delete

const deleteAttendee = loadable(
  (values) => store.dispatch('Attendees/deleteAttendee', values),
  'deleteAttendee',
  getCurrentInstance(),
);
const submit = async () => {
  try {
    await deleteAttendee({ id: id.value });
    closeDialog();
    successToast('Attendee deleted.');
  }
  catch (error) {
    errorToast(getErrorMessage(error, 'Could not delete attendee.'));
  }
};
</script>
