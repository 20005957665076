<template>
  <div class="card">
    <h2 class="card-header">
      attendees
    </h2>
    <div class="card-body">
      <DataTable
        :fields="attendeeFields"
        :rows="currentPage"
        :total-pages="totalPages"
        :search-enabled="true"
        :load-list="getList"
        :is-loading="$isLoading('getListAttendee')"
        :actions="attendeeActions"
        :empty-message="'No attendees'"
        class="w-full"
      />

      <div class="flex flex-row-reverse mt-4">
        <button
          :disabled="$isLoading('getListAttendee')"
          @click="$refs.newAttendeeDialog.openDialog()"
        >
          New Attendee
        </button>
      </div>
    </div>

    <NewAttendeeDialog ref="newAttendeeDialog" />
    <EditAttendeeDialog ref="editAttendeeDialog" />
    <DeleteAttendeeDialog ref="deleteAttendeeDialog" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import omit from 'lodash/omit';
import { loadable } from 'vue-is-loading';
import DataTable from '#ui/components/DataTable';
import { ATTENDEE_MODEL_FIELDS } from '#features/attendees/lib/models/AttendeeModel';
import NewAttendeeDialog from '#features/attendees/ui/dialogs/NewAttendeeDialog';
import EditAttendeeDialog from '#features/attendees/ui/dialogs/EditAttendeeDialog';
import DeleteAttendeeDialog from '#features/attendees/ui/dialogs/DeleteAttendeeDialog';

// Setup

const store = useStore();
const currentPage = computed(() => store.state.Attendees.currentPage);
const totalPages = computed(() => store.state.Attendees.totalPages);
const attendeeFields = computed(() => omit(ATTENDEE_MODEL_FIELDS, 'id'));

// List navigation

const getList = loadable(
  (values) => store.dispatch('Attendees/getList', values),
  'getListAttendee',
  getCurrentInstance(),
);

// Row actions

const editAttendeeDialog = ref(null);
const openEditDialog = (attendee) => {
  editAttendeeDialog.value.openDialog(attendee);
};

const deleteAttendeeDialog = ref(null);
const openDeleteDialog = (attendee) => {
  deleteAttendeeDialog.value.openDialog(attendee);
};

const attendeeActions = [
  { name: 'Edit Attendee', fn: openEditDialog },
  { name: 'Delete Attendee', fn: openDeleteDialog },
];
</script>
