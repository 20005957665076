<template>
  <Dialog
    :visible="visible"
    :style="{ width: '50vw', 'max-width': '500px' }"
    :breakpoints="{'640px': '100vw'}"
    :closable="false"
    :header="`Edit '${form.first_name.value}'`"
  >
    <form
      class="dialog-body"
      @submit.prevent="submit"
    >
      <text-input
        v-model="form.first_name.value"
        class="mb-4"
        input-id="first_name"
        label="First Name"
        :error-text="first_nameErrorText"
        :disabled="$isLoading('createAttendee')"
      />
      <text-input
        v-model="form.last_name.value"
        class="mb-4"
        input-id="last_name"
        label="Last Name"
        :error-text="last_nameErrorText"
        :disabled="$isLoading('createAttendee')"
      />
      <text-input
        v-model="form.email.value"
        class="mb-4"
        input-id="email"
        label="Email"
        :error-text="emailErrorText"
        :disabled="$isLoading('createAttendee')"
      />
      <text-input
        v-model="form.phone_number.value"
        class="mb-4"
        input-id="phone_number"
        label="Phone Number"
        :error-text="phone_numberErrorText"
        :disabled="$isLoading('createAttendee')"
      />
      <text-input
        v-model="form.alias.value"
        class="mb-4"
        input-id="alias"
        label="alias"
        :error-text="aliasErrorText"
        :disabled="$isLoading('createAttendee')"
      />
    </form>

    <div class="dialog-buttons">
      <loading-spinner
        v-if="$isLoading('updateAttendee')"
        :size-class="'fa-2x'"
      />

      <button
        :disabled="$isLoading('updateAttendee')"
        @click="closeDialog"
      >
        Cancel
      </button>

      <button
        class="btn-primary"
        :disabled="! formValid || $isLoading('updateAttendee')"
        @click="submit"
      >
        Edit
      </button>
    </div>
  </Dialog>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { deepUnref } from 'vue-deepunref';
import { loadable } from 'vue-is-loading';
import { getErrorMessage } from '#lib/Errors';
import { fieldErrorText, applyErrors } from '#ui/lib/forms';
import { successToast, errorToast } from '#ui/lib/toast';
import TextInput from '#ui/components/TextInput';
import LoadingSpinner from '#ui/components/LoadingSpinner';
import {
  AttendeeValidator,
  ATTENDEE_FORM_FIELDS,
} from '#features/attendees/lib/validators/AttendeeValidator';

// Setup

const store = useStore();
const id = ref(0);
const form = {
  first_name: ref(''),
  last_name: ref(''),
  email: ref(''),
  phone_number: ref(''),
  alias: ref(''),
};

// Validation

const apiErrors = {
  first_name: ref([]),
  last_name: ref([]),
  email: ref([]),
  phone_number: ref([]),
  alias: ref([]),
};

const validator = new AttendeeValidator(ATTENDEE_FORM_FIELDS);
const first_nameErrorText = computed(fieldErrorText('first_name', validator, form, apiErrors));
const last_nameErrorText = computed(fieldErrorText('last_name', validator, form, apiErrors));
const emailErrorText = computed(fieldErrorText('email', validator, form, apiErrors));
const phone_numberErrorText = computed(fieldErrorText('phone_number', validator, form, apiErrors));
const aliasErrorText = computed(fieldErrorText('alias', validator, form, apiErrors));

const formValid = computed(() => validator.valid(form));
const resetApiErrors = () => {
  apiErrors.first_name.value = [];
  apiErrors.last_name.value = [];
  apiErrors.email.value = [];
  apiErrors.phone_number.value = [];
  apiErrors.alias.value = [];
};

// Open/close

const visible = ref(false);
const openDialog = (attendee) => {
  id.value = attendee.id;
  form.first_name.value = attendee.first_name;
  form.last_name.value = attendee.last_name;
  form.email.value = attendee.email;
  form.phone_number.value = attendee.phone_number;
  form.alias.value = attendee.alias;

  visible.value = true;
};
const closeDialog = () => {
  form.first_name.value = '';
  form.last_name.value = '';
  form.email.value = '';
  form.phone_number.value = '';
  form.alias.value = '';
  visible.value = false;
};
defineExpose({ openDialog, closeDialog });

// Edit Attendee

const updateAttendee = loadable(
  (values) => store.dispatch('Attendees/updateAttendee', values),
  'updateAttendee',
  getCurrentInstance(),
);
const submit = async () => {
  resetApiErrors();

  if (! validator.valid(deepUnref(form))) {
    applyErrors(apiErrors, validator.errors(deepUnref(form), apiErrors));
    return;
  }

  try {
    await updateAttendee({ id: id.value, ...deepUnref(form) });
    closeDialog();
    successToast('Attendee updated.');
  }
  catch (error) {
    errorToast(getErrorMessage(error, 'Could not update Attendee.'));
  }
};
</script>
