const { Validator } = require('#lib/Validator');
// const { Rule } = require('#lib/Rules');
// const { ERROR_EMPTY } = require('#lib/Errors');

// Rules use the validator.js library to validate strings from your forms.
// For documentation about Nodewood Validators, visit: https://nodewood.com/docs/architecture/validators/
// For documentation about the validator.js library, visit: https://www.npmjs.com/package/validator

class AttendeeValidator extends Validator {
  constructor(...args) {
    super(...args);

    this.rules = {
      first_name: [],
      last_name: [],
      email: [],
      phone_number: [],
      alias: [],
    };
  }
}

module.exports = {
  AttendeeValidator,

  ATTENDEE_FORM_FIELDS: [
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'alias',
  ],
};
